import React, { Suspense } from 'react';
import 'antd/dist/antd.css';
import 'antd-mobile/dist/antd-mobile.css';
import 'element-theme-default';
import './App.css';
import { DeviceState } from './function'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from '@/components/pc/editionOne/scrollTop'
import {
  HomePC, MentorPC, AmcPC, IgcsePC, TeacherPC, StoryPC, AlevelPC, APPC, IBPC,
  HomeMobile, AboutPC, PrivacyPC, ChromePC, OperationPC, WindowsPC, AndroidPC,
  IphonePC, IpadPC, ToeflPC, MentorMobile, AmcMobile, IgcseMobile, AlevelMobil, ApMobile, IbMobile, AboutMobile,
  TeacherMobile, StoryMobile, FormMobile, ToeflMobile, ExhibitionPC, ExhibitionMobile, showMobile, expoMobile,
  successMobile, ExpoPc, SignMobile, ToeflCourse, IeltsPC, SatPC, IndexMobile, IeltsMobile, ToeflMobileCourse,
  SpecialPreparationMobile, MobileJiaoTong, Transportation, MobileGuangHuaJianQiao, GuangHua, MobileFuDan,
  FuDan, MobileWanKe, WanKe, WeiYu, MobileWeiYu, MobileQiBaoDwight, DeHuaiTe, MobileLingke, MobileShangshi,
  MobileschoolKnow, LingKe, schoolKnow, jianqiao, ExhibitionMob,Show202104,linkjumpPC
} from './bundle/page';

function App() {
  return (
    <BrowserRouter >
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollToTop>
          <Switch>
            {/* 官网 */}
            <Route exact path="/" component={DeviceState ? IndexMobile : HomePC} />
            <Route path="/mentor" component={DeviceState ? MentorMobile : MentorPC} />
            <Route path="/amc" component={DeviceState ? AmcMobile : AmcPC} />
            <Route path="/igcse" component={DeviceState ? IgcseMobile : IgcsePC} />
            <Route path="/teacher" component={DeviceState ? TeacherMobile : TeacherPC} />
            <Route path="/story" component={DeviceState ? StoryMobile : StoryPC} />
            <Route path="/alevel" component={DeviceState ? AlevelMobil : AlevelPC} />
            <Route path="/ap" component={DeviceState ? ApMobile : APPC} />
            <Route path="/ib" component={DeviceState ? IbMobile : IBPC} />
            <Route path="/about" component={DeviceState ? AboutMobile : AboutPC} />
            <Route path="/privacy" component={PrivacyPC} />
            <Route path="/chrome" component={ChromePC} />
            <Route path="/operation" component={OperationPC} />
            <Route path="/windows" component={WindowsPC} />
            <Route path="/android" component={AndroidPC} />
            <Route path="/iphone" component={IphonePC} />
            <Route path="/ipad" component={IpadPC} />
            <Route path="/form" component={FormMobile} />
            <Route path="/toefl" component={DeviceState ? ToeflMobile : ToeflPC} />
            <Route path="/toefl-course" component={DeviceState ? ToeflMobileCourse : ToeflCourse} />
            {/* 展会 */}
            <Route path="/sign" component={SignMobile} />
            <Route path="/exhibition" component={ExhibitionPC} />
            <Route path="/guojixuexiao" component={ExhibitionMobile} />

            <Route path="/guojixuexiao1" component={ExpoPc} />
            {/* 展会2 */}
            <Route path="/guojixuexiao2" component={expoMobile} />
            {/* 展会(邵颖组) */}
            <Route path="/exhibitions" component={showMobile} />
            <Route path="/successful" component={successMobile} />
              {/* 展会-袁昊 */}
            <Route path="/show202104" component={Show202104} />
            {/* 雅思 */}
            <Route path="/ielts" component={DeviceState ? IeltsMobile : IeltsPC} />


            {/* Sat */}
            <Route path="/sat" component={SatPC} />
            {/* 国际学校备考专题 */}
            <Route path="/zt/bk" component={SpecialPreparationMobile} />

            {/*交通大学 */}
            <Route path="/school/jiaotong" component={DeviceState ? MobileJiaoTong : Transportation} />
            {/* 光华剑桥 */}
            <Route path="/school/guanghuajianqiao" component={DeviceState ? MobileGuangHuaJianQiao : GuangHua} />
            {/* 复旦 */}
            <Route path="/school/fudan" component={DeviceState ? MobileFuDan : FuDan} />
            {/* 万科 */}
            <Route path="/school/wanke" component={DeviceState ? MobileWanKe : WanKe} />
            {/* 位育 */}
            <Route path="/school/weiyu" component={DeviceState ? MobileWeiYu : WeiYu} />
            {/* 七宝德怀特	 */}
            <Route path="/school/qibao" component={DeviceState ? MobileQiBaoDwight : DeHuaiTe} />
            {/* 领科 */}
            <Route path="/school/lingke" component={DeviceState ? MobileLingke : LingKe} />
            {/* 上实剑桥 */}
            <Route path="/school/ssjianqiao" component={DeviceState ? MobileShangshi : jianqiao} />
            {/* 国际学校全知道 */}
            <Route path="/zt/schoolKnow" component={DeviceState ? MobileschoolKnow : schoolKnow} />
            {/* 移动端徐恒豪展会 */}
            <Route path="/blzxz/sh/yd" component={ExhibitionMob} />
            {/* 知乎平台权限跳转 */}
            <Route path="/linkjump" component={linkjumpPC} />

          </Switch>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
