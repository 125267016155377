/*
* 当执行脚本时可以使用一下变量来确认环境，由于 create-react-app 限制环境变量除了 NODE_ENV 和 PUBLIC_URL 两个变量，其他变量都要以REACT_APP_开头
* process.env.NODE_ENV   Node 环境变量
* process.env.PUBLIC_URL   React 公共路径
* process.env.REACT_APP_SCRIPT   同等 process.env.npm_lifecycle_event
*/

// 执行脚本名称
const SCRIPT = process.env.REACT_APP_SCRIPT;
// http方式
const protocol = window.location.protocol;
const host = window.location.host;

// 命名方式  XXX_YYY_ADDRESS， XXX 所属服务，YYY 服务页面。
const address = Object.seal({
  SERVER_MAIN_ADDRESS: null, // 主服务地址
  SERVER_IMAGE_ADDRESS: null, // 图片地址
  SERVER_STUDENT_ADDRESS: null,//学生端地址
});

/*
* 使用方法：
* 1. 以 http 或者 https 开头的地址将不做任何处理
* 2. 不以 / 或者 http,https 开头的地址将会加上 http 方式
* 3. 以 / 开头的地址将会加上 http 方式 和 host，默认 http 方式和 host 都是域名 http 方式和 host
*/

switch (SCRIPT) {
  // 回归环境地址
  case 'build:back':
    address.SERVER_MAIN_ADDRESS = 'https://www.boluozaixian.net/boluo-crm/front';
    address.SERVER_IMAGE_ADDRESS = 'https://pic.boluozaixian.net';
    address.SERVER_STUDENT_ADDRESS = 'https://study.boluozaixian.net';
    break;

  // 生产环境地址
  case 'build':
    address.SERVER_MAIN_ADDRESS = 'https://www.boluozaixian.com/boluo-crm/front';
    address.SERVER_IMAGE_ADDRESS = 'https://pic.boluozaixian.com';
    address.SERVER_STUDENT_ADDRESS = 'https://study.boluozaixian.com';
    break;

  // 默认地址
  default:
    address.SERVER_MAIN_ADDRESS = 'http://192.168.0.254:8094/boluo-crm/front';
    // address.SERVER_IMAGE_ADDRESS = `http://192.168.0.254:2000/boluopic`;
    address.SERVER_IMAGE_ADDRESS = 'https://pic.boluozaixian.net';
    address.SERVER_STUDENT_ADDRESS = 'http://192.168.0.254:8094/student';
    break;
}

// 处理 address
for (const key in address) {
  if (!address[key]) {
    address[key] = `${protocol}//${host}`;
    continue;
  }

  if (address[key][0] === '/') {
    address[key] = `${protocol}//${host}${address[key]}`;
    continue;
  }

  if (!(/^https?:\/\/.*/.test(address[key]))) {
    address[key] = `${protocol}//${address[key]}`;
    continue;
  }
}

export default address;