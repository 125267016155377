import { lazy } from 'react';
// editionOne --- pc
export const HomePC = lazy(() => import('../view/pc/editionThree/home'));
export const MentorPC = lazy(() => import('../view/pc/editionOne/mentor'));
export const AmcPC = lazy(() => import('../view/pc/editionOne/amc'));
export const IgcsePC = lazy(() => import('../view/pc/editionOne/igcse'));
export const TeacherPC = lazy(() => import('../view/pc/editionOne/teacher'));
export const StoryPC = lazy(() => import('../view/pc/editionOne/story'));
export const AboutPC = lazy(() => import('../view/pc/editionOne/about'));
export const AlevelPC = lazy(() => import('../view/pc/editionOne/Alevel'));
export const PrivacyPC = lazy(() => import('../view/pc/editionOne/privacy'));
export const ChromePC = lazy(() => import('../view/pc/editionOne/chrome'));
export const OperationPC = lazy(() => import('../view/pc/editionOne/operation'));
export const WindowsPC = lazy(() => import('../view/pc/editionOne/windows'));
export const AndroidPC = lazy(() => import('../view/pc/editionOne/android'));
export const IphonePC = lazy(() => import('../view/pc/editionOne/iphone'));
export const IpadPC = lazy(() => import('../view/pc/editionOne/ipad'));
export const APPC = lazy(() => import('../view/pc/editionOne/ap'));
export const IBPC = lazy(() => import('../view/pc/editionOne/ib'));
export const ToeflPC = lazy(() => import('../view/pc/editionOne/toefl'));
export const ToeflCourse = lazy(() => import('../view/pc/editionThree/toeflCourse'));
export const SatPC = lazy(() => import('../view/pc/editionThree/sat'));
// editionOne --- mobile
export const HomeMobile = lazy(() => import('../view/mobile/editionOne/home'));
export const AlevelMobil = lazy(() => import('../view/mobile/editionOne/alevel'));
export const ApMobile = lazy(() => import('../view/mobile/editionOne/ap'));
export const IbMobile = lazy(() => import('../view/mobile/editionOne/ib'));
export const IgcseMobile = lazy(() => import('../view/mobile/editionOne/igcse'));
export const AmcMobile = lazy(() => import('../view/mobile/editionOne/amc'));
export const AboutMobile = lazy(() => import('../view/mobile/editionOne/about'));
export const TeacherMobile = lazy(() => import('../view/mobile/editionOne/teacher'));
export const StoryMobile = lazy(() => import('../view/mobile/editionOne/story'));
export const FormMobile = lazy(() => import('../view/mobile/editionOne/form'));
export const ToeflMobile = lazy(() => import('../view/mobile/editionOne/toefl'));
export const MentorMobile = lazy(() => import('../view/mobile/editionOne/mentor'));

// 展会-pc
export const ExhibitionPC = lazy(() => import('../view/pc/exhibition/index'));
export const ExhibitionMobile = lazy(() => import('../view/mobile/exhibition/index'));

export const ExpoPc = lazy(() => import('../view/pc/exhibition/expo'));

// 展会-签到
export const SignMobile = lazy(() => import('../view/mobile/exhibition/sign'));

//展会(邵颖组)
export const showMobile = lazy(() => import('../view/mobile/exhibition/show'));
export const successMobile = lazy(() => import('../view/mobile/exhibition/successful'));
//展会2-移动
export const expoMobile = lazy(() => import('../view/mobile/exhibition/expo'));

// 雅思
export const IeltsPC = lazy(() => import('../view/pc/editionThree/ielts'));
export const IndexMobile = lazy(() => import('../view/mobile/editionThree/home'));
export const IeltsMobile = lazy(() => import('../view/mobile/editionThree/ielts'));

// 移动端托福 editionThree
export const ToeflMobileCourse = lazy(() => import('../view/mobile/editionThree/toefl'));

// 移动端备考专题 editionThree
export const SpecialPreparationMobile = lazy(() => import('../view/mobile/editionThree/mentor'));

// 移动端 editionThree 领科
export const MobileLingke = lazy(() => import('../view/mobile/editionThree/Lingke'));
// 移动端 editionThree 上实剑桥
export const MobileShangshi = lazy(() => import('../view/mobile/editionThree/Shangshi'));
// 移动端 editionThree 国际学校全知道
export const MobileschoolKnow = lazy(() => import('../view/mobile/editionThree/schoolKnow'));

// 移动端 editionThree 交通大学
export const MobileJiaoTong = lazy(() => import('../view/mobile/editionThree/JiaoTong'));
// 移动端 editionThree 光华剑桥
export const MobileGuangHuaJianQiao = lazy(() => import('../view/mobile/editionThree/GuangHuaJianQiao'));
// 移动端 editionThree 复旦
export const MobileFuDan = lazy(() => import('../view/mobile/editionThree/FuDan'));
// 移动端 editionThree 万科
export const MobileWanKe = lazy(() => import('../view/mobile/editionThree/WanKe'));
// 移动端 editionThree 位育
export const MobileWeiYu = lazy(() => import('../view/mobile/editionThree/WeiYu'));
// 移动端 editionThree 七宝德怀特
export const MobileQiBaoDwight = lazy(() => import('../view/mobile/editionThree/QiBaoDwight'));

// pc端 editionThree 交通大学
export const Transportation = lazy(() => import('../view/pc/editionThree/Transportation'));
// pc端 editionThree 光华剑桥
export const GuangHua = lazy(() => import('../view/pc/editionThree/GuangHua'));
// pc端 editionThree 复旦
export const FuDan = lazy(() => import('../view/pc/editionThree/FuDan'));
// pc端 editionThree 万科
export const WanKe = lazy(() => import('../view/pc/editionThree/WanKe'));
// pc端 editionThree 位育
export const WeiYu = lazy(() => import('../view/pc/editionThree/WeiYu'));
// pc端 editionThree 七宝德怀特
export const DeHuaiTe = lazy(() => import('../view/pc/editionThree/DeHuaiTe'));
// pc端 editionThree 领科
export const LingKe = lazy(() => import('../view/pc/editionThree/lingke/lingke.jsx'));
// pc端 editionThree 国际学校全知道
export const schoolKnow = lazy(() => import('../view/pc/editionThree/schoolKnow'));
// pc端 editionThree 上实剑桥
export const jianqiao = lazy(() => import('../view/pc/editionThree/jianqiao'));

// 移动端徐恒豪 展会
export const ExhibitionMob = lazy(() => import('../view/mobile/editionOne/zhanhui'));
// 移动端展会-袁浩昊天
export const Show202104 = lazy(() => import('../view/mobile/exhibition/show202104'));

// 知乎平台权限
export const linkjumpPC = lazy(() => import('../view/pc/editionThree/linkjump'));