import { Toast } from 'antd-mobile';
import address from '../utils/address';
//判断手机端和PC端
export function IsPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
//判断手机端域名
export const DeviceState = (function Device() {
  if (window.location.host.slice(0, 1) === "m" || !IsPC()) {
    return true
  } else {
    return false
  }
})();
// 获取服务器图片
export function getImgName(imgArr, imgUrl, type) {
  var obj = {};
  for (var i = 0; i < imgArr.length; i++) {
    var key = imgArr[i];
    // var value = address.SERVER_IMAGE_ADDRESS + '/' + imgUrl + '/' + imgArr[i] + type + '?v=1'
    var value = address.SERVER_IMAGE_ADDRESS + '/' + imgUrl + '/' + imgArr[i] + type
    obj[key] = value
  }
  return obj;
}
// 获取服务器图片方法二
export function getImage(url, suffix = '') {
  return function _image(name) {
    // return address.SERVER_IMAGE_ADDRESS + '/' + url + '/' + name + suffix + '?v=1';
    return address.SERVER_IMAGE_ADDRESS + '/' + url + '/' + name + suffix;
  };
};
export const VALIDATE_PHONE_REG = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0135678]|19[89]|18[0-9]|14[57])[0-9]{8}$/;

// 获取图片方法三
export const getStaticImage = url => name => {
  const img = name.indexOf(`.`) === -1 ? `${name}.png` : name;
  return url ? `${address.SERVER_IMAGE_ADDRESS}/${url}${img}` : require(`./images/${img}`);
}
//获取浏览器来源
export function getBrowserSource() {
  const pageUrl = document.location.href
  let browserType = ' ';
  if (pageUrl.indexOf('?') !== -1) {//存在问号
    const browserData = pageUrl.slice(pageUrl.indexOf('?') + 1)
    if (browserData.indexOf('&') !== -1) {//存在&
      browserType = browserData.slice(0, browserData.indexOf('&'))
    } else {
      browserType = browserData;
    }
  }
  return browserType
};

// 判断域名
export function getHost() {
  let host = window.location.host;
  if (host.substring(0, 3) == 'www' || /^.*localhost.*$/.test(host)) {
    // www赵天赐的域名
    return true
  } else {
    // edu徐恒豪的域名
    return false
  }
}

// 打开pc在线客服
export function openChat() {
  // window.open("https://w16.53kf.com/webCompany.php?kf_sign=TExNzMTU5OY0ODExNzI0NjE5ODQ2MDA1NzIyNDE0NDc=&arg=10241447&style=1&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&language=zh-cn&charset=GBK&username=&userinfo=&introurl=&lyurl=&lytype=0&copartner=&referer=http%3A%2F%2Flocalhost%3A3000%2Fguojixuexiao1&keyword=http%3A%2F%2Flocalhost%3A3000%2Fibkecheng&brief=&logo=&question=&uid=e7e2a4962c46dae20489bd77164663c8&guest_id=&tfrom=2&timeStamp=1599117660951&ucust_id=","_blank","height=473,width=703,top=200,left=200,status=yes,toolbar=no,menubar=no,resizable=yes,scrollbars=no,location=no,titlebar=no")
  var u = navigator.userAgent, app = navigator.appVersion;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //如果输出结果是true就判定是android终端或者uc浏览器
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //根据输出结果true或者false来判断ios终端
  //!!双叹号一般用来将后面的表达式转换为布尔型的数据（boolean） 
  if (isiOS) {
    window.location.href = "https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1";
  } else {
    var tempwindow = window.open('_blank');
    tempwindow.location = "https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1";
  }

}

// 打开移动在线客服
export function openMobileChat() {
  var u = navigator.userAgent, app = navigator.appVersion;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //如果输出结果是true就判定是android终端或者uc浏览器
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //根据输出结果true或者false来判断ios终端
  //!!双叹号一般用来将后面的表达式转换为布尔型的数据（boolean） 
  if (isiOS) {
    window.location.href = "https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1";
  } else {
    var tempwindow = window.open('_blank');
    tempwindow.location = "https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1";
  }
}

// 移动端rem
export function rem() {
  const docEl = document.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = () => {
      let clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      docEl.style.fontSize = 10 * (clientWidth / 375) + 'px';
      window.plusRem = 10 * (clientWidth / 375);
    };
  recalc();
  if (!window.addEventListener) return;
  window.addEventListener(resizeEvt, recalc, false);
}
//获取地址栏指定参数
export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}
//fetch封装
export const $http = {

  //http://admin.shudailaoshi.com 正式服务器
  //http://101.132.242.122:3030/shudailaoshi-web 测试服务器

  // URL: 'http://192.168.0.254:8080/shudailaoshi-web',
  URL: `/boluo-crm/front`,
  UrlEncode: (obj) => {
    if (!obj || Object.prototype.toString.call(obj) !== '[object Object]') {
      return '';
    }
    let params = [];
    for (let key in obj) {
      params.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return params.join('&');
  },
  get: (Nothis, Objson) => {
    let Alldata = "";
    if (Objson.data) {
      Alldata = "?" + $http.UrlEncode(Objson.data);
    }
    fetch(`${$http.URL}${Objson.url}${Alldata}`, {
      method: 'get',
      mode: "cors",
      headers: {
        'Access-Token': localStorage.getItem("token")
      },
    }).then((res) => {
      if (res.status !== 200) {
        throw res.status
      } else {
        let Data;
        switch (Objson.dataType) {
          case "json":
            {
              Data = res.json()
            }
            break;
          case "text":
            {
              Data = res.test()
            }
            break;
        }
        return Data
      }
    }).then((res) => {
      Objson.success.call(Nothis, res)
    }).catch((error) => {
      if (Objson.error) {
        Objson.error.call(Nothis, error)
      }
    });
  },

  post: (Nothis, Objson) => {
    let formData = new FormData();
    if (Objson.data) {
      let OB = Objson.data;
      for (let i in OB) {
        formData.append(i, OB[i])
      }
    }
    fetch(`${$http.URL}${Objson.url}`, {
      method: 'post',
      mode: "cors",
      headers: {
        'Access-Token': localStorage.getItem("token")
      },
      body: formData
    }).then((res) => {
      if (res.status !== 200) {
        throw res.status
      } else {
        let Data;
        switch (Objson.dataType) {
          case "json":
            {
              Data = res.json()
            }
            break;

          case "text":
            {
              Data = res.test()
            }
            break;
        }
        return Data
      }
    }).then((res) => {
      Objson.success.call(Nothis, res)
    }).catch((error) => {

      if (Objson.error) {
        Objson.error.call(Nothis, error)
      }
    });
  },
  jump: () => {
    fetch(`${$http.URL}/m/live/schedule/facingOneSchedule`, {
      method: 'get',
      mode: "cors",
      headers: {
        'Access-Token': localStorage.getItem("token")
      },
    }).then((res) => {
      if (res.status !== 200) {
        throw res.status
      }
    }).catch((error) => {
      if (isNaN(error)) {
        if (error.message.substring(0, 6) === "Failed") {
          Toast.fail("服务器出错，请联系相关人员")
        }
        return
      }
      if (error === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("thisToken");
        localStorage.setItem("exitState", "2")
        window.location.href = window.location.protocol + "//" + window.location.host + "/login";
      }
    });
  }
};

export function formSubmit(params, suc, fai = () => { }) {
  const { listName, listPhone, attendGrade, activityId, remark, webtitlename } = params
  const phoneCheck = listPhone ? listPhone.replace(/\W/g, '') : '';

  if (listPhone === '') {
    return Toast.fail('请输入手机号码！');
  }

  if (!VALIDATE_PHONE_REG.test(phoneCheck)) {
    return Toast.fail('请输入正确的手机号码！');
  }
  let query = window.location.search
  $http.post(this, {
    url: "/activityList/save",
    dataType: "json",
    data: {
      // "studentName":name,
      // "phone": phone,
      // "grade": grade,
      // "activitySoure": activitySoure,
      // "activityId": activityId,
      // 'ipAddress': window.IPdizhi,
      // "pageSource": getBrowserSource(),
      // 'remark':remarkVal()
      "activityId": activityId,
      "listName": listName,
      "listPhone": phoneCheck,
      "listWechart": '',
      "listTel": '',
      "attendSchool": '',
      "attendGrade": attendGrade,
      "intentionSubject": '',
      "intentionCourse": '',
      "targetSchool": '',
      "ipAddr": window.IPdizhi,
      "srcPage": getBrowserSource(),
      "remark": remark ? `${remark}${query}` : '',
      webtitlename: webtitlename || ''
    },
    success: function (res) {
      if (res.code == 1) {
        suc()
        Toast.success(res.message, 2);
      } else {
        fai()
        Toast.fail(res.message, 2);
      }
    },
    error: function (err) {
      console.log(err)
    }
  })
}

export const clickChat = () => {
  var u = navigator.userAgent, app = navigator.appVersion;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //如果输出结果是true就判定是android终端或者uc浏览器
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //根据输出结果true或者false来判断ios终端
  //!!双叹号一般用来将后面的表达式转换为布尔型的数据（boolean） 

  if (IsPC()) {
    if (isiOS) {
      window.location.href = "https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1";
    } else {
      var tempwindow = window.open('_blank');
      tempwindow.location = "https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1";
    }
  } else {
    // if(isiOS){
    //   _53App.talk('chat','');
    // }else{
    //   _53App.talk('chat','');
    // }
  }

};

//去字符串所有空格
export function Trim(Str) {
  return Str.replace(/(^\s+)|(\s+$)/g, "")
}

// 给后台传remark参数，告诉后台页面是菠萝来源还是树袋来源
export function remarkSource() {
  let reurl = window.location.href;
  let remark = reurl.substring(0, 28);
  if (remark == 'https://www.boluozaixian.com') {
    this.setState({
      remark: 'bl'
    })
  } else {
    this.setState({
      remark: 'bl'
    })
  }
}
//移动端封装
export const plus = {
  // wx:window.wx,
  rem: () => {
    (function (doc, win) {
      let docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
          let clientWidth = docEl.clientWidth;
          if (!clientWidth) return;
          docEl.style.fontSize = 10 * (clientWidth / 320) + 'px';
          window.plusRem = 10 * (clientWidth / 320);
        };
      recalc();
      if (!doc.addEventListener) return;
      win.addEventListener(resizeEvt, recalc, false);
    })(document, window);
    return plus
  },
  rem2: () => {
    const docEl = document.documentElement,
      resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = () => {
        let clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        docEl.style.fontSize = 10 * (clientWidth / 375) + 'px';
        window.plusRem = 10 * (clientWidth / 375);
      };
    recalc();
    if (!window.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
  },
  WeChat: (Array, Func) => {
    let wx = window.wx;
    $http.get(this, {
      url: "/m/wx/front/getSignature",
      dataType: "json",
      data: {
        "url": window.location.href
      },
      success: function (res) {
        wx.config({
          debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxe6f2d53112685daf', // 必填，公众号的唯一标识    
          timestamp: parseInt(res.data.timestamp), // 必填，生成签名的时间戳
          nonceStr: res.data.noncestr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: Array // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          Func(wx)
        });

      },
    });

  },
};