import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {getQueryVariable} from './function/index'
import {IsPC} from '@/function';
if (getQueryVariable('srcUserId')) {
  sessionStorage.setItem('srcUserId',getQueryVariable('srcUserId'))
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// if(IsPC()){
//   function bodyScale() {
//     var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下的可是区域宽度
//     var scale = devicewidth / 1920; // 分母——设计稿的尺寸
//     document.getElementById("root").style.zoom = scale;//放大缩小相应倍数
//     }
//     bodyScale();
// }
// function bodyScale() {
//     var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下的可是区域宽度
//     var scale = devicewidth / 1920; // 分母——设计稿的尺寸
//     document.getElementById("root").style.zoom = scale;//放大缩小相应倍数
//     }
//     bodyScale();
